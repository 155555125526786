import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Whale from "../components/whale"
import Seo from "../components/seo"

const About = ({ location }) => (
  <div className="body">
    <Seo
      pagetitle="ぽんこつクラブとは"
      pagedesc="ぽんこつクラブの魅力をたっぷりと紹介。"
      pagepath={location.pathname}
      pageimg="whale.png"
    />
    <Whale />
    <Layout>
      <main className="about">
        <section className="welcome">
          <h2 className="welcome__title">ぽんこつクラブへようこそ</h2>
          <div className="welcome__container">
            <p className="welcome__text">ここは、ぽんこつの、ぽんこつによる、<br className="welcome__br" />ぽんこつのためのクラブ。<br />ぽんこつな自分の愛しさに気づく場所だ。<br />反資本主義、ミニマリズム、おしゃれイズム。<br />様々な思想やカルチャーなどにインスパイアされたぽんこつフィロソフィを身につけ、<br />君だけのぽんこつラプソディを奏でよう。
            </p>
            <p className="welcome__logo">
              <StaticImage src="../images/logo.png" alt="ロゴ" quality={50} layout="constrained" placeholder="dominantColor" className="welcome__image" />
            </p>
          </div>
        </section>
        <section className="features">
          <h2 className="features__title">ぽんこつクラブが選ばれる理由</h2>
          <div className="features__container">
            <div className="features__first">
              <p className="features__house">
                <StaticImage src="../images/features_house.png" alt="家" quality={50} layout="constrained" placeholder="dominantColor" className="features__image" />
              </p>
              <p className="features__text">友達と入信OK！<br />アットホームな雰囲気です</p>
            </div>
            <div className="features__second">
              <p className="features__pig">
                <StaticImage src="../images/features_pig.png" alt="ブタの貯金箱" quality={50} layout="constrained" placeholder="dominantColor" className="features__image" />
              </p>
              <p className="features__text">嬉しいお布施無料♪<br />オープニング信者大募集</p>
            </div>
            <div className="features__third">
              <p className="features__mochi">
                <StaticImage src="../images/features_mochi.png" alt="おもち" quality={50} layout="constrained" placeholder="dominantColor" className="features__image" />
              </p>
              <p className="features__text">他クラブとのかけもち◎<br />スキマ時間に信仰しちゃおう</p>
            </div>
          </div>
        </section>
        <section className="testimonials">
          <h2 className="testimonials__title">入信者の声</h2>
          <div className="testimonials__kodokkuma">
            <p className="testimonials__picture">
              <StaticImage src="../images/testimonials_kodokkuma.png" alt="コドックマ" quality={50} layout="constrained" placeholder="dominantColor" className="testimonials__image" imgStyle={{ borderRadius: '100%' }} />
            </p>
            <div className="testimonials__container">
              <h3 className="testimonials__catchphrase">"地球温暖化が止まりました"</h3>
              <p className="testimonials__text">
                ぽんこつクラブに加入してから地球温暖化が止まりました！ 愚かな人間どものせいで本当にクマっていたんですが、ぽんこつクラブのおかげできんたまの皮一枚つながりました。マッチングアプリのBearsでかわいいクマとマッチングするし、お得な価格で壺まで買えちゃうし、いいことだらけです！ 寂しくなくなりました！
              </p>
              <p className="testimonials__name">コドックマ（かき氷屋スタッフ）</p>
            </div>
          </div>
          <div className="testimonials__bigpuppy">
            <p className="testimonials__picture">
              <StaticImage src="../images/testimonials_bigpuppy.png" alt="ビッグ・パピィ" quality={50} layout="constrained" placeholder="dominantColor" className="testimonials__image" imgStyle={{ borderRadius: '100%' }} />
            </p>
            <div className="testimonials__container">
              <h3 className="testimonials__catchphrase">"俺はこういうワンちゃんだ"</h3>
              <p className="testimonials__text">ぽんこつクラブに入信してから、マカ不思議なことに勃起力が50％アップ（当社比）しました！ 自信がついて、何もかもうまくいくから驚きです。自分がオンリーワンなワンちゃんであることに気づかせてくれたぽんこつクラブには、感謝の言葉しかありません。俺はこういうワンちゃんだと胸を張って、今日もワンダフルライフを謳歌します！</p>
              <p className="testimonials__name">ビッグ・パピィ（見習い整体師）</p>
            </div>
          </div>
        </section>
        <section className="faq">
          <h2 className="faq__title">たまにある質問</h2>
          <div className="faq__container">
            <p className="faq__text">Q. 誰でも入信できますか？</p>
            <p className="faq__text">A. はい、ぽんこつなお方であればどなた様でもご入信いただけます。</p>
            <p className="faq__text">Q. 幸せになれますか？</p>
            <p className="faq__text">A. 信じる者は救われます。</p>
          </div>
        </section>
      </main>
    </Layout>
  </div>
)

export default About