import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Parallax from "react-rellax"

const Whale = () => (
  <div className="whale">
    <p className="whale__background"><Parallax speed={-5}><StaticImage src="../images/whale_background.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></Parallax></p>
    <p className="whale__sea"><StaticImage src="../images/whale_sea.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__whale"><StaticImage src="../images/whale_whale.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__tail"><StaticImage src="../images/whale_tail.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__fin"><StaticImage src="../images/whale_fin.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__spray2"><StaticImage src="../images/whale_spray2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__spray1"><StaticImage src="../images/whale_spray1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__bear"><StaticImage src="../images/whale_bear.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__float"><StaticImage src="../images/whale_float.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__bubble1"><StaticImage src="../images/whale_bubble1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__bubble2"><StaticImage src="../images/whale_bubble2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__bubble3"><StaticImage src="../images/whale_bubble3.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__bubble4"><StaticImage src="../images/whale_bubble4.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__bubble5"><StaticImage src="../images/whale_bubble5.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
    <p className="whale__bubble6"><StaticImage src="../images/whale_bubble6.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="whale__image" /></p>
  </div>
)

export default Whale